/** Типы способа покупки. */
export const PurchaseConditions = {
    Undefined: 'Undefined',     // Неизвестный статус
    Immediate: 'Immediate',     // Полная оплата.
    Mortgage: 'Mortgage',       // Ипотека.
    Installment: 'Installment'  // Рассрочка.
} as const;

export const defaultInitialPaymentPercent = 30; // Первоначальный взнос в процентах по умолчанию
export const defaultPeriodInYears = 10; // Срок в годах по умолчанию (для ипотеки)
export const maxPeriodInYears = 30; // Максимальный срок в годах (для ипотеки)