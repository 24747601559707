import { defineStore } from 'pinia';
import type {
    IBookingMortgageFilter,
    IBookingInstallmentFilter,
    PurchaseCondition
} from '~/layers/booking/types/purchase-condition';
import {
    PurchaseConditions,
    defaultPeriodInYears
} from '~/layers/booking/constants/purchase-condition';
import type { IBookingMortgageProgramFilter, MortgageType } from '~/layers/booking/types/bookingMortgage';

export const usePaymentConditionsStore = defineStore('paymentConditions', () => {
    const purchaseCondition = ref<PurchaseCondition>(PurchaseConditions.Immediate); // выбранный способ покупки
    const updatedRealtyPrice = ref<number | null>(null); // Измененная цена ОН.
    const mortgageProgramType = ref<MortgageType | null>(null); // Выбранный тип ипотечного предложения.

    /** Фильтры из ипотечных настроек при выборе оплаты */
    const mortgagePurchaseFilters = ref<IBookingMortgageFilter>({
        realtyId: 0,
        realtyPrice: 0,
        initialPayment: 0,
        periodInYears: defaultPeriodInYears,
        mortgageProgramType: undefined,
    });

    /** Фильтры настроек рассрочки при выборе оплаты */
    const installmentPurchaseFilters = ref<IBookingInstallmentFilter>({
        realtyId: 0,
        realtyPrice: 0,
        installmentProgramType: 'Flat',
        initialPayment: 50,
        periodInMonths: 12,
    });

    const mortgageProgramsFilter = ref<IBookingMortgageProgramFilter>({
        realtyId: 0,
        realtyPrice: 0,
        initialPayment: 0,
        periodInYears: defaultPeriodInYears,
        mortgageProgramType: undefined,
    });

    const maskedMortgageInitialPayment = ref<string>('');

    return {
        updatedRealtyPrice,
        mortgageProgramType,
        purchaseCondition,
        mortgagePurchaseFilters,
        installmentPurchaseFilters,
        mortgageProgramsFilter,
        maskedMortgageInitialPayment,
    };
});
